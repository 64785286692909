import { $themeBreakpoints } from '@themeConfig'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    user: null,
    setorAtual: '',
    notificacoesChannel: null,
    notificacoesChat: [],
    notificacoes: [],
    chatChannel: null,
    notificacoesMeta: {},
    windowWidth: 0,
    shallShowOverlay: false,
    listaSetores: [
      {
        nome: 'Engenharia',
        value: 'engenharia',
      },
      {
        nome: 'Execução',
        value: 'execução',
      },
      {
        nome: 'Comercial',
        value: 'comercial',
      }],

  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_CHAT_CHANNEL(state, val) {
      state.chatChannel = val
    },
    SET_NOTIFICACOES_CHANNEL(state, val) {
      state.notificacoesChannel = val
    },
    SET_USER(state, val) {
      state.user = val
      localStorage.setItem('userData', JSON.stringify(val))
    },
    CLEAR_USER(state) {
      state.user = []
    },
    SET_NOTIFICACOES_CHAT(state, val) {
      state.notificacoesChat = val
    },
    SET_NOTIFICACOES(state, val) {
      const combinedArray = state.notificacoes.concat(val)

      // Filtra o array para remover itens duplicados
      const uniqueArray = combinedArray.filter((item, index, self) => index === self.findIndex(t => (
        t.id === item.id
      )))

      state.notificacoes = uniqueArray
    },
    SET_NOTIFICACOES_META(state, val) {
      state.notificacoesMeta = val
    },
    SET_SETOR_ATUAL(state, val) {
      state.user.preferences.setorAtual = val
    },
  },
  actions: {
    async atualizarSetorAtual({ commit }, setor) {
      commit('SET_SETOR_ATUAL', setor)

      await axios.post('/auth/user/preferences', {
        key: 'setorAtual',
        value: setor,
      })
    },
    limparNotificacoes({ commit }) {
      commit('SET_NOTIFICACOES', [])
      commit('SET_NOTIFICACOES_META', {})
    },

    updateUserSession({ commit }, user) {
      commit('SET_USER', user)
    },
    async updateChatNotifications({ commit }) {
      try {
        const { data } = await axios.get('/chats')

        commit('SET_NOTIFICACOES_CHAT', data.data)
      } catch (error) {
        commit('SET_NOTIFICACOES_CHAT', [])
        console.error(error)
      }
    },
    // eslint-disable-next-line consistent-return
    async updateNotifications({
      commit,
      dispatch,
    }, params) {
      try {
        const { data } = await axios.get('/notificacoes', {
          params: {
            page: params?.page || 1,
            order: 'created_at',
            dir: 'desc',
          },
        })

        commit('SET_NOTIFICACOES', data.data)
        commit('SET_NOTIFICACOES_META', data.meta)
        return data
      } catch (error) {
        dispatch('limparNotificacoes')
        console.error(error)
      }
    },
    async readMessageChat(ctx, chatId) {
      try {
        await axios.put(`/chats/${chatId}/messages/read`)
      } catch (error) {
        console.error(error)
      }
    },
    async readNotification(ctx, id) {
      let url = '/notificacoes/read'
      if (id) {
        url = `/notificacoes/${id}/read`
      }
      try {
        await axios.put(url)
      } catch (error) {
        console.error(error)
      }
    },
    async setOnlineStatus(ctx, status) {
      await axios.put('/auth/user/online', { online: status })
    },

    async atualizarSessaoUsuario({ commit }) {
      const { data } = await axios.get('/auth/user')
      commit('SET_USER', data)
    },
    async personificarUsuario({ commit }, uuid) {
      const { data } = await axios.get(`/auth/impersonate/${uuid}`)

      localStorage.setItem('newPersonificateToken', data.accessToken)
      localStorage.setItem('oldPersonificateToken', localStorage.getItem('accessToken'))
      localStorage.setItem('accessToken', localStorage.getItem('newPersonificateToken'))
      localStorage.setItem('oldUserData', localStorage.getItem('userData'))

      commit('SET_USER', data.userData)
    },
    async logoutSistema({ commit }) {
      await axios.get('/auth/logout')
      commit('CLEAR_USER')
    },
  },
}
